import React from "react";
import { css } from "@emotion/core";
import { IndexPageQuery, TagPageQuery } from "generated/types/gatsby";
import { PageLink } from "./PageLink";

interface ContentListProps {
  readonly edges:
    | IndexPageQuery["allMdx"]["edges"]
    | TagPageQuery["allMdx"]["edges"];
}

const list = css`
  line-height: 1.8;
  list-style: none;
  padding: 0;
  margin: 1rem 0 2rem;
  text-rendering: optimizeLegibility;
`;

const item = css``;

export const ContentList = ({ edges }: ContentListProps) => (
  <ul css={list}>
    {edges.map(({ node }) => {
      const { path, title } = node.frontmatter;
      return (
        <li css={item} key={path}>
          <PageLink to={path}>{title}</PageLink> ({node.frontmatter.date})
        </li>
      );
    })}
  </ul>
);
